@use "base";

// ListCategory

.list-title {
  font-family: "HyliaSerifBeta", sans-serif !important;
  color: base.$light-gold;
  text-transform: capitalize;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ad7a2928;
  flex-basis: 100%;
  flex: 1;
  padding-top: 0.3em;
}

.list {
  width: 90vw;
  margin-top: 1rem !important;
}

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

// ListEntry
.list-item {
  max-width: 95%;
  border: 3px solid base.$gold;
  border-radius: 0.5em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  margin-bottom: 0.6em;
  background-color: base.$green;
  background-image: base.$bi-main;
  box-shadow: 1px 1px 4px -1px;
}

.list-item:hover {
  background-color: base.$nav-green;
  background-image: base.$bi-main;
  box-shadow: 2px 2px 8px -2px;
}

.list-stack {
  width: 100%;
}

.list-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.list-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-transform: capitalize;
  width: 100%;
}

.list-name {
  font-size: 1rem !important;
}

.list-name:hover {
  color: base.$gold;
  cursor: pointer;
}

@media (max-width: 850px) {
  .list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .list {
    width: 95vw;
  }
  .list-item {
    width: 90%;
    max-width: 90%;
  }
  .list-name {
    font-size: 1.65rem !important;
  }
  .list-subinfo {
    font-size: 1.5rem !important;
  }
  .list-image {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 430px) {
  .list-name {
    font-size: 1.25rem !important;
  }
  .list-subinfo {
    font-size: 1.1rem !important;
  }
  .list-image {
    width: 100px;
    height: 100px;
    margin-left: -0.5em !important;
  }
}
