@use "base";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: visible;
  max-height: 85vh;
  background-color: base.$bc-main;
  background-image: base.$bi-main;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 75%;
  box-shadow: inset 0px 0px 4px 4px rgba(24, 140, 91, 0.7647058824);
  padding: 1em;
  outline: none;
}

.modal-details {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.detail-box {
  color: #2e2e2e;
  min-width: fit-content;
  flex: 1;
  text-align: center;
  border-radius: 10px;
  overflow: auto;
  padding: 5px;
  max-width: 70%;
  text-transform: capitalize;
  background-color: #e4d1b2;
  background-image: base.$bi-modal;

  .label {
    color: base.$text-green;
    font-family: "HyliaSerifBeta";
  }
  .value {
    color: #3a3a3a;
    font-weight: 400;
  }
}

.title {
  color: base.$text-green;
  font-weight: "bold";
  text-transform: "capitalize";
}

.id {
  flex: 1% 0;
  color: #2e2e2e;
}

.image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 5px;
  overflow: auto;
  padding: 3px;
  background-color: base.$gold;
  background-image: url("https://www.transparenttextures.com/patterns/exclusive-paper.png");
}

.description {
  color: #2e2e2e;
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 5px;
  max-width: 80%;
  background-color: #e4d1b266;
  border-radius: 10px;
  margin-top: 2em !important;
  font-size: 0.9em !important;
}

@media (max-width: 768px) {
  .modal {
    width: 90%;
    max-height: 90vh;
  }
  .detail-box {
    max-width: 80%;
  }
  .description {
    max-width: 100%;
    font-size: 1.25em !important;
  }
}

@media (max-width: 430px) {
  .modal {
    width: 95%;
    max-height: 80vh;
    overflow: hidden;
  }
  .modal-details {
    max-height: 30%;
    overflow-y: scroll;
    margin-top: 0.05em !important;
  }
  .detail-box {
    max-width: 90%;
  }
  .description {
    max-width: 100%;
    max-height: 60%;
    font-size: 1.25em !important;
    margin-top: 0.25em !important;
  }
}
