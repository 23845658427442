//color scheme
$dark-green: #0e5135;
$green: #0d9263;
$nav-green: #188c5bc3;
$light-green: #4aba91;
$gold: #b8b361;
$light-gold: #e3e2b8;

// text colors
$text-green: #0b412b;

// background colors
$bc-main: #ad7b29;

// background images
$bi-main: url("https://www.transparenttextures.com/patterns/cardboard-flat.png");
$bi-modal: url("https://www.transparenttextures.com/patterns/exclusive-paper.png");

@mixin bg-main {
  background-color: $bc-main;
  background-image: $bi-main;
}
