@use "base";

.Nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-evenly;
  width: 100%;
  max-width: 90vw;
  margin: 1em auto;
}

.Nav-item {
  height: 4.5em;
  width: 16em;
  max-width: 12%;
  border: 4px double black;
  box-shadow: 6px 4px 8px -2px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: base.$nav-green;
  background-image: base.$bi-modal;
  font-family: "HyliaSerifBeta";
  font-size: 1.1rem;
  color: base.$light-gold;
  cursor: pointer;
}

.Nav-item:hover {
  background-color: #0d9263;
  background-image: base.$bi-modal;
  color: base.$gold;
}

.Nav-item:active {
  background-color: #0d9263;
  background-image: base.$bi-modal;
  color: base.$gold;
}

.Nav-item:focus {
  background-color: #0d9263;
  background-image: base.$bi-modal;
  color: base.$gold;
}

@media (max-width: 850px) {
  .Nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Nav-item {
    width: 90%;
    max-width: 90%;
    margin-bottom: 0.5em !important;
    font-size: 2rem;
    height: fit-content;
    padding: 0.5em;
  }
}
